<template>
  <a class="block panel wrapper-lg bg-info item">
    <i class="fa-fw text-3x text-white m-r-xs" :class="icon"></i>
    <span class="text-white font-thin h2 block m-t-sm">{{ title }}</span>
    <div class="text-muted text-md m-t-sm">{{subtitle}}</div>
  </a>
</template>

<script>
export default {
name: "BtnCanal",
  props: ['title', 'subtitle', 'icon']
}
</script>
