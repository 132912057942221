<script>
import BtnCanal from "@/components/marketing/components/BtnCanal"
export default {
  name: 'MarketingCriarCampanha',
  inject: {
    erplayout: {
      default() {
        console.error('Main needs to be child of ErpLayout')
      }
    }
  },
  directives: {},
  components: {BtnCanal},
  data() {
    return {}
  },
  computed: {
  },
  mounted() {
    this.erplayout.erpheader.menu.setActiveMenu('marketing')
  },
  destroyed() {
    // document.querySelector('.u-erp-layout-container').classList.remove('bg-white')
  },
  methods: {},
  meta: {
    title: 'Criar Campanha - Marketing',
    name: 'Criar Campanha - Marketing'
  }
}
</script>

<template>
  <div class="mkt-module">
    <transition name="fade" appear>
      <div class="transition-generic w-full wrapper-lg">
        <h2 class="text-center m-t-lg">Escolha o Tipo de Campanha que Deseja Enviar</h2>
        <p class="text-center"><a @click="$router.push({name: 'marketing.main'})">Voltar</a></p>
        <div class="campanhas-criar-btns m-t-xl b-row b-row-sm text-center">

          <div class="b-col-sm-4">
            <btn-canal @click.native="$router.push({name: 'marketing.criar-campanha.canal', params: {id: 0, canal: 'email', action: 'destinatarios'}})" title="Campanha de E-mail" subtitle="Enviar um e-mail HTML juntamente com uma versão alternativa de texto simples." icon="fal fa-envelope" />
          </div>
          <div class="b-col-sm-4">
            <btn-canal disabled @click="$router.push({name: 'marketing.criar-campanha.canal', params: {id: 0, canal: 'sms', action: 'destinatarios'}})" title="SMS" subtitle="Envie uma mensagem no celular dos destinatários." icon="fas fa-mobile-alt" />
          </div>
          <div class="b-col-sm-4">
            <btn-canal disabled @click="$router.push({name: 'marketing.criar-campanha.canal', params: {id: 0, canal: 'whatsapp', action: 'destinatarios'}})" title="Whatsapp" subtitle="Envie Whatsapp com Texto, Áudio e Vídeo para os destinatários." icon="fab fa-whatsapp" />
          </div>
          <div class="b-col-sm-4">
            <btn-canal disabled @click="$router.push({name: 'marketing.criar-campanha.canal', params: {id: 0, canal: 'voz', action: 'destinatarios'}})" title="Voz" subtitle="Grave um áudio faça ligações automáticas com ele." icon="fal fa-phone" />
          </div>


          <!--<div class="ctipo b-t padder-v">
              <div class="b-col-sm-8 b-col-xs-12">
                  <a class="h3 text-info">Campanha de E-mail</a>
                  <div class="text-md">Enviar um e-mail HTML juntamente com uma versão alternativa de texto simples.</div>
              </div>
              <div class="b-col-sm-4 b-col-xs-12">
                  <div class="m-t hidden-sm hidden-md hidden-lg"></div>
                  <a class="btn btn-default pull-right">Selecionar</a>
              </div>
              <div class="clearfix"></div>
          </div>-->




        </div>
      </div>
    </transition>
  </div>
</template>

